.Container {
  position: relative;
  display: inline-block;
  border-radius: 5px 5px 0 0;
  background-color: var(--color-gray4);
  border-bottom: var(--color-gray2) 1px solid;
  font-family: Work Sans;
  font-size: 16px;
  letter-spacing: -0.04em;
  height: 50px;
  vertical-align: top;
  outline: 0;
  user-select: none;
}

.Container.HasFocus {
  border-bottom-color: var(--color-blue0);
}

.Select {
  display: none;
}

.Icon {
  display: flex;
  height: 100%;
  width: 40px;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.Label {
  pointer-events: none;
  display: block;
  width: 100%;
  height: 0;
  line-height: 1.2em;
  font-size: inherit;
  padding: 16px 16px 0;
  transition:
    padding 100ms,
    font-size 100ms;
  color: var(--color-gray1);
}

.Icon + .Label {
  padding-left: 40px;
}

.Container.HasValue > .Label {
  font-size: 14px;
  padding-top: 6px;
}

.Header {
  display: block;
  height: 100%;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  letter-spacing: inherit;
  border-radius: inherit;
  outline: 0;
  border: 0;
  background-color: transparent;
  padding: 2px 16px 0;
  line-height: 3em;
  cursor: pointer;
}

.Label + .Header {
  margin-top: -16px;
  transition: margin-top 100ms;
  line-height: 1.95em;
}

.Icon + .Label + .Header {
  padding-left: 40px;
}

.Container.HasValue > .Label + .Header {
  padding-top: 18px;
  margin-top: -6px;
}

.Placeholder {
  color: var(--color-gray1);
}

.Options {
  position: relative;
  top: 1px;
  z-index: 100;
  display: flex;
  flex-flow: column;
  background-color: #ffffff;
  border: var(--color-gray3) 1px solid;
  border-top: 0;
  border-radius: 0 0 5px 5px;
  overflow: auto;
}

.Option > span {
  display: block;
  outline: 0;
  padding: 8px 16px;
  cursor: pointer;
}

.Option.Hovered {
  background-color: var(--color-gray4);
}
