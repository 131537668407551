.Container {
  display: inline-block;
  height: 50px;
  vertical-align: top;
  fill: var(--color-gray1);
}

.Query {
  width: 100%;
  vertical-align: top;
  height: 50px;
}

.Items {
  position: relative;
  z-index: 1;
  width: 100%;
  max-height: 280px;
  overflow-y: scroll;
  background-color: #ffffff;
  border: var(--color-gray3) 1px solid;
  border-top: 0;
  border-radius: 0 0 5px 5px;
  transition: max-height 200ms ease;
}

.Container:not(.Expanded) > .Items {
  max-height: 0;
  border-bottom: 0;
}

.Item {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  outline: 0;
  padding: 8px 16px;
  cursor: pointer;
}

.Item:hover {
  background-color: var(--color-gray4);
}
