.Container .Button {
    font-size: 12px;
    line-height: inherit;
  }
  
  .Content {
    min-width: 260px;
    max-width: 300px;
    user-select: none;
  }
  
  .Header {
    text-align: center;
    border-bottom: var(--color-gray2) 1px solid;
    padding: 0 0 12px;
    margin: 0 0 12px;
    font-weight: 900;
  }
  
  .Subtitle {
    padding: 0 0 12px;
    margin: 0 0 12px;
    font-weight: 900;
    font-size: 15px;
  }
  
  .TipLists {
    max-height: 300px;
    overflow-y: auto;
  }
  
  .TipLists > .TipList {
    display: block;
    color: #000000;
    padding: 4px 12px 4px 4px;
    margin: 2px 0;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  .TipLists > strong {
    display: block;
  }
  
  .TipLists > strong ~ strong {
    margin-top: 12px;
  }
  
  .Borrowers {
    display: grid;
    justify-content: center;
    gap: 4px;
    font-size: 12px;
    align-items: center;
    color: var(--color-gray1);
  }
  
  .Footer {
    text-align: center;
    border-top: var(--color-gray2) 1px solid;
    padding: 12px 0 0;
    margin: 12px 0 0;
  }
  
  .Footer > .Create {
    font-weight: 900;
  }
  
  
