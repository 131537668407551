.Container {
  border: 0;
  background-color: transparent;
  font-family: Work Sans;
  font-size: 16px;
  letter-spacing: inherit;
  outline: 0;
  cursor: pointer;
  padding: 0;
  line-height: inherit;
}

.Text.Primary {
  color: var(--color-blue0);
}

.Text.Secondary {
  color: #000000;
}

.Fill,
.Outline {
  height: 40px;
  padding: 0 20px;
  border-radius: 15px;
  font-weight: bold;
}

.Outline.Primary {
  color: var(--color-blue0);
  border: var(--color-blue0) 1px solid;
}

.Outline.Secondary {
  color: #000000;
  border: var(--color-gray0) 1px solid;
}

.Fill.Primary {
  background-color: var(--color-blue0);
  color: #ffffff;
}

.Fill.Secondary {
  background-color: #000000;
  color: #ffffff;
}

.Fill.Third {
  background-color: #ffffff;
  color: #000000;
}

.Fill.Fourth {
  background-color: var(--color-red0);
  color: #ffffff;
}

.Fill.Fifth {
  background-color: var(--color-orange0);
  color: #ffffff;
}
