.Container {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  pointer-events: none;
  padding: 10px 10px 20vh;
  z-index: 3000;
}

.Container > *:not(:first-child) {
  margin-top: 15px;
}

.Notification {
  position: relative;
  max-width: 450px;
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0);
  transition: box-shadow 300ms;
}

.Notification.Expanded {
  box-shadow: 0 0 5px rgba(0, 0, 0, 1);
}

.Wrapper {
  text-align: center;
  display: block;
  border-radius: 15px;
  color: #ffffff;
  padding: 30px;
  pointer-events: all;
}

.Neutral {
  background-color: var(--color-blue0);
}

.Alert {
  background-color: var(--color-orange0);
}

.Error {
  background-color: var(--color-red0);
}

.Close {
  position: absolute;
  top: 12px;
  right: 14px;
  line-height: 0;
  fill: #ffffff;
}
