.Container.Small {
  display: flex;
}

.Header {
  position: relative;
  border-radius: inherit;
}

.Container.Small > .Header {
  width: 200px;
}

.Header > a {
  border-radius: inherit;
}

.Image {
  border-radius: inherit;
  display: block;
  max-height: 200px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.Container.Small > .Header .Image {
  max-height: unset;
}

.Container.Small > .Header .Image {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.Container.Medium > .Header .Image {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.Options {
  position: absolute;
  top: 8px;
  right: 8px;
}

.OptionsButton,
.OptionsIcon {
  vertical-align: top;
}

.Option {
  display: block;
  width: 100%;
  text-align: left;
  padding: 6px;
}

.Option + .Option {
  margin-top: 6px;
}

.Icon {
  vertical-align: middle;
  margin-right: 8px;
  width: 24px;
}

.EditButton > * {
  color: var(--color-gray0);
  fill: var(--color-gray0);
}

.RemoveButton > * {
  color: var(--color-red0);
  fill: var(--color-red0);
}

.Main {
  display: flex;
  padding: 6px;
}

.Container.Small > .Main {
  flex-basis: calc(100% - 200px);
}

.Actions {
  flex-basis: 60px;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 6px;
}

.Actions > * {
  margin: 6px 0;
  word-break: break-word;
}

.Actions > .Action {
  line-height: 1.2em;
  display: flex;
  flex-flow: column;
  align-items: center;
  fill: var(--color-gray1);
  color: var(--color-gray1);
}

.Action > * {
  display: block;
  font-size: 12px;
}

.Action.Active > * {
  color: var(--color-blue0);
  fill: var(--color-blue0);
}

.Content {
  flex-basis: calc(100% - 60px);
  padding: 6px;
}

.Title {
  color: #000000;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 4px;
  word-break: break-word;
}

.Location {
  font-size: 16px;
  font-weight: 100;
  margin-bottom: 4px;
  word-break: break-word;
}

.User {
  font-size: 14px;
  word-break: break-word;
}

.Description {
  margin-bottom: 8px;
  word-break: break-word;
}

.Avatar {
  width: 34px;
  height: 34px;
  object-fit: cover;
  border-radius: 50%;
}