.Container {
  height: 80px;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 2;
}

.Content {
  height: 100%;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  
}

@media only screen and (max-width: 600px) {
  .Content {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
  }
  .Container {
    height: auto;
  }
}

.Logo {
  grid-column: 1;
  font-weight: bold;
  font-size: 25px;
  color: #000000;
  letter-spacing: -0.035em;
}

.Search {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.SearchInput {
  max-width: 600px;
  width: 100%;
}

.SearchInputWrapper {
  border-color: var(--color-gray2);
}

.Menu {
  grid-column: 3;
  display: flex;
  align-items: center;
  fill: #000000;
}

.Menu > *:not(:first-child) {
  margin-left: 13px;
}

.Menu > *:not(:last-child) {
  margin-right: 13px;
}

.Menu > a,
.Menu > button {
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}

.Account {
  vertical-align: top;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
  outline: 0;
}

.ContextOptions {
  min-width: 180px;
}

.ContextOptions > *:not(:first-child) {
  margin-top: 16px;
}

.ContextOptions .CreateButton {
  color: var(--color-gray1);
  fill: var(--color-gray1);
  display: grid;
  grid-template-columns: 24px 1fr;
  gap: 5px;
  align-items: center;
}

.ContextOptions .AccountButton {
  color: #000000;
}

.EditAccountButton {
  cursor: pointer;
  color: #000000;
}

.ContextOptions .SignOut {
  color: var(--color-red1);
}

.ContextDrafts {
  height: 18px;
}

.DraftButton {
  position: relative;
}

.DraftButton > span {
  position: absolute;
  top: -10px;
  left: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  background-color: var(--color-orange0);
  border-radius: 50%;
  height: 24px;
  width: 24px;
  color: #ffffff;
}

.DraftTitle {
  display: block;
  margin-bottom: 16px;
}

.DraftList {
  max-height: 300px;
  overflow: auto;
}

.DraftList li {
  display: flex;
  justify-content: space-between;
}

.DraftList li:not(:first-child) {
  margin-top: 12px;
}

.VenueName {
  color: var(--color-gray5);
}

.TipDraftButton {
  line-height: 20px;
}