.Container {
  display: grid;
  grid-template-columns: 70px 1fr;
  gap: 10px;
  align-items: center;
}

.Image {
  border-radius: 50%;
  display: block;
}

.Image > img {
  vertical-align: top;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
}

.Info {
  display: grid;
  line-height: 1em;
  justify-content: flex-start;
  gap: 4px;
  word-break: break-word;
  grid-template-columns: 1fr;
}

.Name {
  font-weight: 600;
}

.Large .Description {
  font-style: italic;
  margin-top: 15px;
}

.City,
.MemberSince,
.Description {
  font-size: 14px;
}

.City,
.MemberSince,
.TipsAndLists {
  display: none;
}

/* Small UserCard. */
.Container.Small {
  grid-template-columns: 24px 1fr;
}

.Small .Image {
  height: 24px;
  width: 24px;
}

.Small .Name {
  font-size: 12px;
}

/* Medium UserCard. */
.Medium .Image {
  width: 70px;
  height: 70px;
}

.Medium .City,
.Medium .TipsAndLists {
  display: inline-block;
}

/* Large UserCard. */
.Large.Container {
  grid-template-columns: unset;
  color: var(--color-gray1);
}

.Large .Image {
  margin: 0 auto;
  width: 80px;
  height: 80px;
}

.Large .Info {
  text-align: center;
  gap: 2px;
}

.Large .Name,
.Large .City,
.Large .MemberSince {
  margin: 0 auto;
  display: inline-block;
}

/* User data pending. */
.Container.Pending .Image {
  animation: var(--pending-pulse);
}

.Container.Pending .Name,
.Container.Pending .City,
.Container.Pending .MemberSince,
.Container.Pending .TipsAndLists,
.Container.Pending .Description {
  animation: var(--pending-pulse);
  height: 18px;
  border-radius: 6px;
  width: 100%;
}

.Container.Pending .Name {
  max-width: 150px;
}

.Container.Pending .City {
  max-width: 180px;
}

.Container.Pending .MemberSince {
  max-width: 130px;
}
