@keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

.spinner {

    display: inline-block;
    border-color: grey;
    border-style: solid;
    border-radius: 99999px;
    border-width: 2px;
    width: 20px;
    height: 20px;
    border-bottom-color: transparent;
    border-left-color: transparent;
    animation: spin 0.45s linear infinite;

}
