.Container {
  background-color: #000000;
  color: #ffffff;
  padding: 20px 0;
}

.Content {
  min-height: 220px;
  height: 100%;
  font-size: 14px;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.Content > *:not(:first-child) {
  margin-top: 40px;
}

.SiteLinks {
  display: inline-flex;
  flex-flow: row wrap;
  overflow: hidden;
  list-style: disc;
}

.SiteLinks > *:not(:first-child) {
  margin-left: 12px;
}

.SiteLinks > *:not(:last-child) {
  margin-right: 12px;
}

.SiteLinks > li::marker {
  font-size: 10px;
}

.SiteLinks a {
  color: #ffffff;
}

.Info {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 1000px) {
  .Info {
    flex-direction: column;
  }
  .Info > * {
    margin-bottom: 20px;
  }
}

.Info > .SiteLinks {
  justify-self: center;
}

.SocialLinks {
  display: inline-flex;
}

.SocialLinks > *:not(:first-child) {
  margin-left: 10px;
}

.SocialLinks > *:not(:last-child) {
  margin-right: 10px;
}
