.Container {
  display: grid;
  grid-template-rows: max-content 1fr;
  background-color: #ffffff;
  max-width: 680px;
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  max-height: 800px;
}

.Container.TipView {
  max-width: 800px;
}

.Header {
  position: relative;
  text-align: center;
  padding: 30px;
}

.BackButton {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px;
  fill: var(--color-gray1);
}

.CloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;
  fill: var(--color-gray1);
}

.Content {
  overflow: auto;
  margin: 0 10px 24px;
  padding: 0 14px;
}

.Items {
  display: flex;
  flex-flow: column;
}

.Items > :not(:first-child) {
  margin-top: 20px;
}

.Tip,
.User {
  border-radius: 15px;
  border: var(--color-gray2) 1px solid;
}

.User {
  display: grid;
  grid-template-columns: 75px 1fr;
  padding: 18px;
  gap: 18px;
}

.Image {
  width: 75px;
  height: 75px;
  object-fit: cover;
  border-radius: 50%;
}

.Info {
  font-size: 13px;
  display: flex;
  flex-flow: column;
  line-height: 1.5em;
  align-items: flex-start;
}

.Name {
  font-size: 14px;
  font-weight: bold;
}

.BorrowedCount {
  font-size: inherit;
  line-height: inherit;
  font-weight: 600;
  margin-bottom: 4px;
}

.BorrowedCount > span {
  color: var(--color-blue0);
}

.Description {
  color: var(--color-gray1);
}

@media screen and (max-height: 820px) {
  .Container {
    height: 100%;
  }
}
