.Container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  z-index: 2000;
}

.BackDrop {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  overflow: auto;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  overflow: auto;
}

.BackDrop > * {
  margin: auto !important;
}
