.Container {
  display: inline-flex;
  flex-flow: row wrap;
  border-radius: 5px 5px 0 0;
  background-color: var(--color-gray4);
  border-bottom: var(--color-gray2) 1px solid;
  font-family: Work Sans;
  font-size: 16px;
  letter-spacing: -0.04em;
  height: 50px;
  vertical-align: top;
  outline: 0;
  cursor: text;
}

.Container.HasFocus {
  border-bottom-color: var(--color-blue0);
}

.Icon {
  display: flex;
  height: 100%;
  width: 40px;
  justify-content: center;
  align-items: center;
}

.Label {
  position: relative;
  bottom: 100%;
  display: block;
  width: 100%;
  height: 0;
  line-height: 1.2em;
  font-size: inherit;
  padding: 16px 16px 0;
  transition:
    padding 100ms,
    font-size 100ms;
  color: var(--color-gray1);
  pointer-events: none;
}

.Icon ~ .Label {
  padding-left: 40px;
}

.Container.HasValue > .Label,
.Container.HasFocus > .Label {
  font-size: 14px;
  padding-top: 6px;
}

.Input {
  display: block;
  height: 100%;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  letter-spacing: inherit;
  border-radius: inherit;
  outline: 0;
  border: 0;
  background-color: transparent;
  padding: 2px 16px 0;
  line-height: 1;
}

.Icon + .Input {
  width: calc(100% - 40px);
  padding-left: 0;
}

.Input::placeholder {
  color: var(--color-gray1);
}

.Container.HasValue.HasLabel > .Input,
.Container.HasFocus.HasLabel > .Input {
  padding-top: 18px;
}
