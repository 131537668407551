.Container {
    display: inline-block;
    position: relative;
    vertical-align: top;
  }
  
  .ContextMenuWrapper {
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
  }
  
  .Button {
    vertical-align: top;
    display: inline-block;
    outline: 0;
  }
  
  .Menu {
    position: absolute;
    width: max-content;
    background-color: #ffffff;
    border-radius: 15px;
    display: block;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    opacity: 0;
    z-index: 1;
  }
  
  .Container.LeftAlign .Menu {
    left: 0;
  }
  
  .Container.RightAlign .Menu {
    right: 0;
  }
  
  .Container.TopAlign .Menu {
    top: calc( 100% + 10px);
  }
  
  .Container.BottomAlign .Menu {
    bottom: calc( 100% + 10px);
  }
  
  .Menu.Expanded {
    opacity: 1;
  }
  
  .Wrapper {
    padding: 16px;
    outline: 0;
  }
  