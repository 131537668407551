.Container {
  display: flex;
  flex-flow: column;
  text-align: center;
  padding: 30px 40px;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}

.Actions {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.Actions > *:not(:first-child) {
  margin-left: 8px;
}
