.Container {
  background-color: #ffffff;
  max-width: 680px;
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}

.Header {
  position: relative;
  text-align: center;
  padding: 30px;
}

.CloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;
  fill: var(--color-gray1);
}

.Content {
  display: flex;
  flex-flow: column;
  padding: 0 24px 24px;
  align-items: center;
}

.Content > *:not(:first-child) {
  margin-top: 40px;
}

.Description {
  max-width: 320px;
  text-align: center;
}

.Options {
  display: flex;
  flex-flow: column;
  max-width: 240px;
  width: 100%;
  align-items: center;
}

.Options > *:not(:first-child) {
  margin-top: 20px;
}

.OptionsButton {
  font-weight: normal;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 12px;
}

.OptionsIcon {
  margin-right: 6px;
}

.Email {
  fill: var(--color-gray1);
}

.Navigation {
  text-align: center;
}

.Navigation.Bold {
  font-weight: bold;
}

.Navigation.Bold > button {
  font-weight: bold;
}

.Navigation > button {
  display: inline-flex;
  align-items: center;
}

.ToC {
  color: var(--color-gray1);
}

.Form {
  display: flex;
  flex-flow: column;
  max-width: 220px;
  width: 100%;
}

.Form > *:not(:first-child) {
  margin-top: 15px;
}

.Input {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  width: 100%;
}
