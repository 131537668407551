.Status {
  display: block;
  position: absolute;
  top: 8px;
  left: 8px;
  padding: 4px 12px;
  border-radius: 15px;
  color: #ffffff;
  user-select: none;
}

.Status.Draft {
  background-color: var(--color-orange0);
}

.Status.Published {
  background-color: var(--color-green0);
}

.MoreInfo {
  font-size: 18px;
  margin-top: 8px;
}

.MoreInfo:not(.Expanded) {
  color: var(--color-gray1);
  fill: var(--color-gray1)
}

.MoreInfo > .Icon {
  vertical-align: middle;
  fill: inherit;
  margin-left: 8px;
  position: relative;
  top: -1px;
}

.MoreInfo.Expanded {
  color: var(--color-blue0);
  fill: var(--color-blue0);
}

.VenueDetails {
  margin-top: 8px;
  word-break: break-word;
}

.VenueDetails > li {
  padding: 8px 0;
}

.VenueDetails > li > .Icon {
  fill: var(--color-gray1);
  vertical-align: top;
  margin-right: 8px;
}
