.Container {
  border-radius: 15px;
  overflow: hidden;
  align-self: flex-start;
  border: var(--color-gray2) 1px solid;
}

.Title {
  color: #000000;
  font-size: 30px;
  font-weight: 900;
  word-break: break-word;
  line-height: 0.9;
}

.Name {
  font-size: 16px;
  font-weight: 600;
  margin-left: 8px;
}

.Content {
  padding: 16px
}

.User {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.Info {
  font-size: 18;
  color: #000000;
  font-weight: 400;
}