.Container {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0 0 16px;
}

.Content {
  max-width: 800px;
}

.Title {
  display: flex;
  justify-content: space-between;
  margin: 24px 0;
  padding: 0 0 24px;
  border-bottom: 1px solid var(--color-gray3);
}

.Fields > *:not(:first-child) {
  margin-top: 16px;
}

.Input {
  width: 100%;
}

.CharCounter {
  color: var(--color-gray1);
  font-size: 12px;
  padding-left: 16px;
}

.CloseButton {
  fill: var(--color-gray1);
}

.Actions {
  display: flex;
  justify-content: space-between;
}

.Actions > div > *:not(:first-child) {
  margin-left: 8px;
}

.Actions > *:not(:first-child) {
  margin-left: 8px;
}
