.Container {
  position: relative;
}

.Overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  opacity: 0.8;
}

.Overlay.Hovered {
  opacity: 1;
}
