.Container {
  background-color: #ffffff;
  max-width: 680px;
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  height: fit-content;
}

.Header {
  position: relative;
  text-align: center;
  padding: 30px;
}

.CloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;
  fill: var(--color-gray1);
}

.Images {
  height: 254px;
  position: relative;
}

.ImageFile {
  display: none;
}

.CoverImage {
  height: 200px;
  position: relative;
  background-color: var(--color-gray3);
}

.CoverImage > img {
  width: 100%;
  height: 100%;
  vertical-align: top;
  object-fit: cover;
}

.AddPhotoButton {
  position: absolute;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
  fill: #ffffff;
  stroke: var(--color-gray1);
}

.ProfileImage {
  height: 108px;
  width: 108px;
  position: absolute;
  bottom: 0;
  left: 30px;
  border: #ffffff 4px solid;
  background-color: #ffffff;
  border-radius: 50%;
  overflow: hidden;
}

.ProfileImage > img {
  width: 100%;
  height: 100%;
  vertical-align: top;
  object-fit: cover;
}

.ImageDropOverlay {
  border-radius: inherit;
  width: 100%;
  height: 100%;
  background-color: rgba(20, 70, 77, 0.8);
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-shadow: 0 0 5px #000000;
  line-height: 1.2em;
}

.ImageDropOverlay::before {
  display: block;
  content: '';
  position: absolute;
  top: 4px;
  left: 4px;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  border: var(--color-blue0) 3px dashed;
  border-radius: inherit;
}

.Info {
  display: grid;
  gap: 24px;
  padding: 24px;
  grid-template-columns: calc(50% - 12px) calc(50% - 12px);
}

.Input {
  width: 100%;
}

.FullSpan {
  grid-column: 1 / -1;
  max-width: 100%;
  min-width: 100%;
}

.CharCounter {
  color: var(--color-gray1);
  font-size: 12px;
  padding-left: 16px;
}

.Actions {
  display: flex;
  justify-content: flex-end;
  padding: 0 24px 24px;
}

.Actions > *:not(:first-child) {
  margin-left: 16px;
}
