.Container {
  border: 1px solid var(--color-gray3);
  border-radius: 15px;
  padding: 12px 16px;
  min-width: 400px;
}

.Container > strong {
  display: block;
  padding: 0 8px 12px;
  text-align: center;
  border-bottom: 1px solid var(--color-gray3);
  margin-bottom: 12px;
}

.TipLists {
  max-height: 200px;
  overflow: auto;
}

.TipLists > *:not(:first-child) {
  margin-top: 24px;
}

.TipLists > .Items > .Header {
  font-weight: bold;
}

.AddToTipListIcon {
  height: 8px;
}

.AddToTipListIcon.Selected {
  fill: var(--color-green0);
}
