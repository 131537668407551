.Container {
  fill: #000000;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.Container svg {
  fill: inherit;
  display: block;
}

.Container.Circle {
  width: 34px;
  height: 34px;
  border-radius: 50%;
}

.Container.Circle.Restaurant {
  background-color: #A8F5FF;
}

.Container.Circle.Hotels {
  background-color: #FFF1A8;
}

.Container.Circle.Nightlife {
  background-color: #D3A8FF;
}

.Container.Circle.Shopping {
  background-color: #FFCCA8;
}

.Container.Circle.Dos {
  background-color: #A8FFAB;
}

.Container.Circle.Donts {
  background-color: #FFA8A8;
}
