.Container {
  display: inline-block;
  border-radius: 5px 5px 0 0;
  background-color: var(--color-gray4);
  border-bottom: var(--color-gray2) 1px solid;
  font-family: Work Sans;
  font-size: 16px;
  letter-spacing: -0.04em;
  height: fit-content;
  vertical-align: top;
  overflow: auto;
}

.Container.HasFocus {
  border-bottom-color: var(--color-blue0);
}

.Label {
  pointer-events: none;
  display: block;
  width: 100%;
  height: 0;
  line-height: 1.2em;
  font-size: inherit;
  padding: 16px 16px 0;
  transition:
    padding 100ms,
    font-size 100ms;
  color: var(--color-gray1);
}

.Container.HasValue > .Label,
.Container.HasFocus > .Label {
  font-size: 14px;
  padding-top: 6px;
}

.TextArea {
  height: 49px;
  display: block;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  letter-spacing: inherit;
  border-radius: inherit;
  outline: 0;
  border: 0;
  background-color: transparent;
  padding: 16px 16px 0;
  line-height: 1.3em;
  resize: none;
}

.Label + .TextArea {
  margin-top: -16px;
  transition: margin-top 100ms;
}

.Container.HasValue > .Label + .TextArea,
.Container.HasFocus > .Label + .TextArea {
  padding-top: 24px;
  margin-top: -6px;
}
