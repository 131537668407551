.Container {
  font-family: Work Sans;
  line-height: 1em;
  font-size: 16px;
  display: inline-block;
  height: 40px;
  position: relative;
  border-radius: 15px;
}

.Wrapper {
  display: inline-block;
  width: 100%;
  align-items: center;
  min-height: 100%;
  border: var(--color-gray0) 1px solid;
  background-color: #ffffff;
  border-radius: 15px;
}

.InputWrapper {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 16px;
}

.Icon {
  margin-top: 2px;
  margin-right: 10px;
}

.Input {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  height: 100%;
  width: 100%;
  border-radius: inherit;
  outline: 0;
  border: 0;
  background-color: inherit;
  padding: 0;
}

.Input::placeholder {
  color: var(--color-gray1);
  font-weight: 100;
}

.SearchResultWrapper {
  display: block;
  width: 100%;
  top: 100%;
  left: 0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.SearchResult {
  color: #000000;
  margin: 8px 8px 20px 20px;
  max-height: calc(100vh - 120px);
  overflow: auto;
}

.SearchResult > .Category:not(:first-child) {
  margin-top: 8px;
}

.Title {
  display: block;
}

.Item {
  outline: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 8px;
  color: unset;
  margin-top: 2px;
}

.Item:hover {
  background-color: var(--color-gray4);
}

.Item > * {
  display: inline-block;
}

.Item > *:not(:first-child) {
  margin-left: 8px;
}

.Highlight {
  color: var(--color-blue0);
}

.Details {
  color: var(--color-gray5);
}

.ProfileImage {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
}
