.ImageFile {
  display: none;
}

.Image {
  background-color: var(--color-gray4);
  border-radius: 15px;
  padding: 20px;
  text-align: center;
}

.DropArea {
  padding: 50px 25px;
  border-radius: 15px;
  border: var(--color-gray0) 2px dashed;
}

.Overlay {
  border-radius: inherit;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 133, 255, 0.4);
}

.Error {
  width: 100%;
  text-align: center;
}

.Preview {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.Preview > img {
  max-width: 100%;
  max-height: 200px;
  margin-bottom: 20px;
}

.Preview > .RemoveButton {
  color: var(--color-red0);
}
