:root {
  --color-blue0: #0085ff;
  --color-blue1: #70a4d1;
  --color-blue2: #a6cef1;
  --color-blue3: #e3f2ff;
  --color-gray0: #545454;
  --color-gray1: #666666;
  --color-gray2: #cecece;
  --color-gray3: #e2e2e2;
  --color-gray4: #eeeeee;
  --color-gray5: #a3a3a3;
  --color-orange0: #ff7a00;
  --color-orange1: #fff2e4;
  --color-red0: #d40e0e;
  --color-red1: #f50000;
  --color-green0: #34a853;
  --pending-pulse: pulse infinite 3000ms;
}

body {
  font-family: Work Sans;
  font-size: 16px;
  line-height: 1.5em;
  letter-spacing: -0.02em;
}

p {
  line-height: 1.5em;
}

a {
  text-decoration: none;
  color: var(--color-blue0);
}

h1 {
  font-weight: bold;
  font-size: 40px;
  line-height: 1.1em;
}

h2 {
  font-weight: 100;
  font-size: 34px;
  line-height: 1.1em;
}

h3 {
  font-weight: 500;
  font-size: 28px;
  line-height: 1.1em;
}

h4 {
  font-weight: bold;
  font-size: 25px;
}

h5 {
  font-size: 18px;
  font-weight: bold;
}

strong {
  font-weight: 600;
}

body *::-webkit-scrollbar {
  width: 6px;
}

body *::-webkit-scrollbar-thumb {
  background-color: var(--color-gray1);
  border-radius: 5px;
}

body *::-webkit-scrollbar-track {
  background-color: transparent;
}

@keyframes pulse {
  0% {
    background-color: var(--color-blue1);
  }
  50% {
    background-color: var(--color-blue2);
  }
  100% {
    background-color: var(--color-blue1);
  }
}
