.Container {
  display: grid;
  grid-template-rows: max-content 1fr;
  background-color: #ffffff;
  max-width: 800px;
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  max-height: 800px;
}

.Header {
  position: relative;
  text-align: center;
  padding: 30px;
}

.CloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;
  fill: var(--color-gray1);
}

.Content {
  overflow: auto;
  margin: 0 10px 24px;
  padding: 0 14px;
}

.TipLists {
  display: flex;
  flex-flow: column;
}

.TipLists > *:not(:first-child) {
  margin-top: 20px;
}

.TipList {
  border-radius: 15px;
  border: var(--color-gray2) 1px solid;
}

@media screen and (max-height: 820px) {
  .Container {
    height: 100%;
  }
}
